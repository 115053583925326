import { FlokiAction, FlokiHistoryService } from '@cosmobile/ng-floki-types'
import { Injectable } from '@angular/core'
import { ActionHandler } from '@cosmobile/ng-floki-types/lib/interfaces/action-handler.interface'
import { Type } from '@cosmobile/floki-base'
import { ActivatedRoute, Router } from '@angular/router';

@FlokiAction('user-favorites')
@Injectable()
export class UserFavoritesAction implements ActionHandler {
  icon = {
    name: 'heart',
    color: 'red',
  }
  name = 'USER_FAVORITES'

  constructor(
    // private service: PublishService,
    // private langService: FlokiI18nService,
    protected readonly router: Router
  ) {}

  isVisible(type: Type, related: any, currentRoute: ActivatedRoute) {
    return true
  }

  async handle(type: Type, related: any, currentRoute: ActivatedRoute) {
    if (related && related.id) {
      this.router.navigate([`/types/CustomerFavorite`], {queryParams: {title: `${related.email} (${related.trademark})` , search: JSON.stringify({'customer.id': related.id})}})
    }
  }
}
