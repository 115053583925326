import { FlokiAction } from '@cosmobile/ng-floki-types'
import { Injectable } from '@angular/core'
import { ActionHandler } from '@cosmobile/ng-floki-types/lib/interfaces/action-handler.interface'
import { Type } from '@cosmobile/floki-base'
import { ActivatedRoute } from '@angular/router'
import { PublishService } from '../services/publish.service'
import { FlokiI18nService } from '@cosmobile/ng-floki-i18n'
import { Language } from '@cosmobile/ng-floki-i18n'

@FlokiAction('catalogo-pub-build')
@Injectable()
export class CatalogoPubBuildAction implements ActionHandler {
    icon = {
        name: 'cogs',
        color: '#d5aa41',
    }
    name = 'CATALOGO_PUB_BUILD'

    constructor(
        private service: PublishService,
        private langService: FlokiI18nService
    ) {}

    isVisible(type: Type, related: any, currentRoute: ActivatedRoute) {
        let visible = false
        if (related && related.attributeSet.name && ('Settore' === related.attributeSet.name || 'SettoreColoranti' === related.attributeSet.name)) {
            visible = true
        }
        return visible
    }

    async handle(type: Type, related: any, currentRoute: ActivatedRoute) {
        let lang: string = null
        const langObj: Language = this.langService.selectedLanguage
        if (langObj) {
            lang = langObj.id
        }
        if ('Gerarchia' === type.name) {
            if ('SettoreColoranti' === related.attributeSet.name) {
                await this.service.buildCatalogoProdotti('coloranti', related.marchio, lang)
            } else {
                await this.service.buildCatalogoProdotti(related.name, related.marchio, lang)
            }
        }
    }
}
