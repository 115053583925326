import { Component, OnInit, Input } from '@angular/core'
import { ClipboardService } from 'ngx-clipboard'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'url-copy',
  template: `
    <button mat-icon-button (click)="copyUrl()">
      <mat-icon>link</mat-icon>
    </button>
  `,
})
export class UrlCopyComponent implements OnInit {
  @Input()
  url: string
  @Input()
  message: string
  constructor(
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {}

  copyUrl() {
    this.clipboard.copyFromContent(this.url)
    this.snackbar.open(this.message, undefined, { duration: 1000 })
  }
}
