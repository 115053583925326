import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import filesize from 'filesize'
import { UploadService } from '../../services/upload.service'

@Component({
  selector: 'asset-library',
  templateUrl: './asset-library.component.html',
  styleUrls: ['./asset-library.component.scss'],
})
export class AssetLibraryComponent implements OnInit {
  displayedColumns: string[] = ['copy', 'name', 'type']
  dataSource: MatTableDataSource<any>
  assets = []
  showUploader = false
  currentDirectory = '/'
  constructor(
    private uploadService: UploadService,
    @Inject(DOCUMENT)
    private document: Document,
  ) {}

  get filter() {
    return this.dataSource && this.dataSource.filter
  }
  set filter(filterValue: string) {
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim()
    }
  }

  ngOnInit() {
    this.load()
  }

  async load() {
    const assets: any[] = await this.uploadService.get(this.currentDirectory)
    const filter = this.filter
    this.dataSource = new MatTableDataSource<any>(
      assets
        .map(asset => {
          if (asset.type === 'file') {
            asset.size = filesize(asset.size)
          }
          asset.mimetype = this.iconByType(asset.mimetype)
          return asset
        })
        .sort((a, b) => {
          const aIsFile = a.type === 'file'
          const bIsFile = b.type === 'file'
          if (a.name === '..') {
            return -1
          }

          if (b.name === '..') {
            return 1
          }
          if (aIsFile === bIsFile) {
            return a.name.localeCompare(b.name)
          } else {
            return aIsFile ? 1 : -1
          }
        }),
    )
    this.dataSource.filterPredicate = (data, filt) => {
      return data.name.indexOf(filt) >= 0
    }
    this.dataSource.filter = filter
  }

  getUrl(asset) {
    return this.document.location.origin + asset.path
  }

  iconByType(type: string | false | undefined) {
    if (type === false) {
      return 'insert_drive_file'
    }

    if (type == null) {
      return 'folder'
    }

    if (type.startsWith('image')) {
      return 'photo'
    } else if (type.startsWith('video')) {
      return 'movie'
    }

    return 'description'
  }

  changeCurrentDirectory(e, path) {
    e.preventDefault()
    console.log(path)
    this.currentDirectory = path.replace(/^\/upload/, '')
    console.log(this.currentDirectory)
    this.load()
  }

  async upload(file) {
    await this.uploadService.upload(file)
    this.showUploader = false
    this.filter = file.originalname
    this.load()
  }

  async back() {
    this.showUploader = false
    this.load()
  }
}
