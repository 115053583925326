import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Asset } from '../entities/asset'

@Injectable()
export class UploadService {
  private adminEndpoint = 'api/upload/assets'
  private endpoint = 'api/upload'
  constructor(private http: HttpClient) {}

  getEndpoint() {
    return this.endpoint
  }

  async upload(file: any) {
    const formData = new FormData()
    formData.append('file', file, file.name)
    const result: any = await this.http
      .post(this.adminEndpoint, formData)
      .toPromise()
    return result
  }

  async getByPath(path: string): Promise<Asset> {
    return this.http.get<Asset>(this.endpoint + path + '?stats=1').toPromise()
    const endpoint = this.adminEndpoint
    const assets: Asset[] = await this.http
      .get<Asset[]>(`${endpoint}/getByPath`, {
        params: new HttpParams({
          fromObject: {
            path,
          },
        }),
      })
      .toPromise()
    return assets[0]
  }

  async download(path: string) {
    const endpoint = this.endpoint
    return await this.http.get(`${endpoint}/${path}`).toPromise()
  }

  async getStats(path: string): Promise<any> {
    return this.http
      .get(this.endpoint + path, { params: { stats: '1' } })
      .toPromise()
  }

  async get(path: string): Promise<any> {
    return this.http.get(this.endpoint + path).toPromise()
  }
}
