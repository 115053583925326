import { FlokiAction, FlokiHistoryService } from '@cosmobile/ng-floki-types'
import { Injectable } from '@angular/core'
import { ActionHandler } from '@cosmobile/ng-floki-types/lib/interfaces/action-handler.interface'
import { Type } from '@cosmobile/floki-base'
import { ActivatedRoute, Router } from '@angular/router';
import { PublishService } from '../services/publish.service'
import { FlokiI18nService } from '@cosmobile/ng-floki-i18n'
import { Language } from '@cosmobile/ng-floki-i18n'

@FlokiAction('user-downloads')
@Injectable()
export class UserDownloadsAction implements ActionHandler {
  icon = {
    name: 'download',
    color: 'green',
  }
  name = 'USER_DOWNLOADS'

  constructor(
    // private service: PublishService,
    // private langService: FlokiI18nService,
    protected readonly router: Router
  ) {}

  isVisible(type: Type, related: any, currentRoute: ActivatedRoute) {
    return true
  }

  async handle(type: Type, related: any, currentRoute: ActivatedRoute) {
    if (related && related.id) {
      this.router.navigate([`/types/CustomerDownload`], {queryParams: {title: `${related.email} (${related.trademark})` , search: JSON.stringify({'customer.id': related.id})}})
    }
  }
}
