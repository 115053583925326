import { FlokiAction, ActionHandler } from '@cosmobile/ng-floki-types'
import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Type } from '@cosmobile/floki-base'
import { SyncService } from '../services/sync.service'
import { DialogService } from '@cosmobile/ng-floki-dialog'
import { FlokiI18nService, Language } from '@cosmobile/ng-floki-i18n'

@FlokiAction('launch-sync-action')
@Injectable()
export class LaunchSyncAction implements ActionHandler {
  icon = 'sync'
  name = 'Sincornizzazione totale'

  constructor(
    protected readonly dialogService: DialogService,
    protected readonly router: Router,
    private readonly syncService: SyncService, 
    private readonly langService: FlokiI18nService
  ) {}

  isVisible(type: Type, related: any, currentRoute: ActivatedRoute) {
    if (!related) {
      return true
  }
  return false
  }

  async handle(type: Type, related: any, currentRoute: ActivatedRoute) {
    try {
      let lang = 'it-it'
      const langObj: Language = this.langService.selectedLanguage
      if (langObj) {
        lang = langObj.id
      }
      const resp: { syncId: string, error?: string } = await this.syncService.launchSync(lang)
      if(resp.error && resp.error === 'progressError') {
        this.dialogService.openHtmlDialog({
          id: 'error-launch-dialog',
          title: 'Errore in avvio sincronizzazione',
          content: `<div><h2>Sincronizzazione ancora in corso</h2></div>`
        })
      } else {
        this.dialogService.openTextDialog({
          id: 'launch-dialog',
          title: `Sincronizzazione ${resp.syncId} lanciata`,
          content: 'Verificare lista e dettaglio per lo stato di avanzamento'
        })
      }
    } catch (error) {
      this.dialogService.openHtmlDialog({
        id: 'error-launch-dialog',
        title: 'Errore in avvio sincronizzazione',
        content: `<div><h2>${error.message}</h2></div>`
      })
    } finally {
      setTimeout(() => {
        this.dialogService.closeDialog()
        this.router.navigate([''], {skipLocationChange: true}).then(
          () => this.router.navigate([`/types/${type.name}`], {skipLocationChange: true})
        )
      }, 3000)
    }
  }
}
