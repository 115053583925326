import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './components/app/app.component'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import {
  HttpClientModule,
  HttpClient,
} from '@angular/common/http'
import {
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatExpansionModule } from '@angular/material/expansion'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FontawesomeModule } from '@cosmobile/ng-fontawesome'
import { FlokiI18nModule } from '@cosmobile/ng-floki-i18n'
import { environment } from '../environments/environment'
import { FlokiCategoraModule } from '@cosmobile/ng-floki-categora'
import { FlokiAssetModule } from './modules/ng-floki-asset/floki-asset.module'
import { CopyLinkAction } from './actions/copy-link.action'
import { SchedaTecnicaPubAction } from './actions/scheda-tecnica-pub.action'
import { PublishService } from './services/publish.service'
import { FlokiTypesModule } from '@cosmobile/ng-floki-types';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { FlokiAuthModule } from '@cosmobile/ng-floki-auth'
import { CatalogoPubGetAction } from './actions/catalogo-pub-get-action'
import { ListinoPubGetAction } from './actions/listino-pub-get-action'
import { LoaderModule } from '@cosmobile/ng-loader'
import { DialogModule } from '@cosmobile/ng-floki-dialog'
import { CatalogoPubBuildAction } from './actions/catalogo-pub-build-action'
import { ListinoPubBuildAction } from './actions/listino-pub-build-action'
import { LaunchSyncStaticAction } from './actions/launch-sync-static.action'
import { LaunchSyncAction } from './actions/launch-sync.action'
import { SyncService } from './services/sync.service';
import { UserDownloadsAction } from './actions/user-downloads.action';
import { UserFavoritesAction } from './actions/user-favorites.action';
import { SerieDuplicateAction } from './actions/serie-duplicate.action';
import { SerieService } from './services/serie.service'
import { SerieDuplicateUsaAction } from './actions/serie-duplicate-usa.action';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/api/i18n/', '')
}

@NgModule({
  declarations: [AppComponent, DashboardComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DialogModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    HttpClientModule,
    MatListModule,
    MatTooltipModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    MatSnackBarModule,
    FontawesomeModule,
    LoaderModule.forRoot({
      minTime: 1000,
      icon: {
          name: 'spinner-third',
          style: 'solid',
          size: '5x',
          animate: 'spin',
          color: '#3f51b5'
      }
    }),
    FlokiTypesModule,
    FlokiCategoraModule,
    FlokiAssetModule,
    FlokiAuthModule.forRoot({ apiUrl: '/api' }),
    FlokiI18nModule.forRoot({
      apiUrl: environment.baseApiUrl,
      defaultLanguage: {
        id: 'it-it',
        name: 'Italiano',
        countryCode: 'IT',
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    AppRoutingModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'it-it' },
    CopyLinkAction,
    PublishService,
    SyncService,
    SchedaTecnicaPubAction,
    SerieDuplicateAction,
    SerieDuplicateUsaAction,
    UserDownloadsAction,
    UserFavoritesAction,
    CatalogoPubGetAction,
    CatalogoPubBuildAction,
    ListinoPubGetAction,
    ListinoPubBuildAction,
    LaunchSyncAction,
    LaunchSyncStaticAction,
    SerieService
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
