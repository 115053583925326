import { NgModule } from '@angular/core'
import { AssetLibraryComponent } from './components/asset-library/asset-library.component'
import { UploadService } from './services/upload.service'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatCardModule } from '@angular/material/card'
import { MatTableModule } from '@angular/material/table'
import { TranslateModule } from '@ngx-translate/core'
import { UrlCopyComponent } from './components/url-copy/url-copy.component'
import { CommonModule } from '@angular/common'
import { NgxDropzoneModule } from 'ngx-dropzone'

@NgModule({
  declarations: [AssetLibraryComponent, UrlCopyComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({ isolate: false }),
    MatIconModule,
    MatFormFieldModule,
    MatCardModule,
    MatTableModule,
    NgxDropzoneModule,
  ],
  providers: [UploadService],
  exports: [AssetLibraryComponent, UrlCopyComponent],
})
export class FlokiAssetModule {}
