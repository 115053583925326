import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable()
export class SyncService {

  private syncApi = `${environment.baseApiUrl}/elastic/sync`

  constructor(
    public http: HttpClient
  ) {}

  async launchSync(lang: string): Promise<{ syncId: string, error?: string }> {
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json')
      const response: { syncId: string, error?: string } = await this.http.post<{ syncId: string }>(`${this.syncApi}?lang=${lang}`, { headers }).toPromise()
      if(!response) {
        throw new Error('response falsy launchSync')
      }
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async launchStaticSync(): Promise<{ syncId: string, error?: string }> {
    try {
      const headers = new HttpHeaders().set('Content-Type', 'application/json')
      const response: { syncId: string, error?: string } = await this.http.post<{ syncId: string }>(`${this.syncApi}?index=estalia-static-pages`, { headers }).toPromise()
      if(!response) {
        throw new Error('response falsy launchStaticSync')
      }
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

}
