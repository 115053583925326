import { FlokiAction } from '@cosmobile/ng-floki-types'
import { Injectable } from '@angular/core'
import { ActionHandler } from '@cosmobile/ng-floki-types/lib/interfaces/action-handler.interface'
import { Type } from '@cosmobile/floki-base'
import { ActivatedRoute } from '@angular/router'
import { PublishService } from '../services/publish.service'
import { FlokiI18nService } from '@cosmobile/ng-floki-i18n'
import { Language } from '@cosmobile/ng-floki-i18n'

@FlokiAction('scheda-tecnica-pub')
@Injectable()
export class SchedaTecnicaPubAction implements ActionHandler {
  icon = {
    name: 'clipboard',
    color: 'gray',
  }
  name = 'SCHEDA_TECNICA_PUB'

  constructor(
    private service: PublishService,
    private langService: FlokiI18nService
  ) {}

  isVisible(type: Type, related: any, currentRoute: ActivatedRoute) {
    let visible = false
    if (related && related.attributeSet.name && 'Serie' === related.attributeSet.name && related.lineaST) {
      visible = true
    }
    return visible
  }

  async handle(type: Type, related: any, currentRoute: ActivatedRoute) {
    let lang: string = null
    const langObj: Language = this.langService.selectedLanguage
    if (langObj) {
      lang = langObj.id
    }
    if ('Serie' === type.name) {
      await this.service.xmlSchedaTecnicaSerieProdotti(related.name, lang, related.marchio, related.descrizione)
    }
  }
}
