import { MediaMatcher } from '@angular/cdk/layout'
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import { Title } from '@angular/platform-browser'
import { NavigationEnd, Router } from '@angular/router'
import { Type } from '@cosmobile/floki-base'
import { FlokiAuthService } from '@cosmobile/ng-floki-auth'
import { FlokiI18nService } from '@cosmobile/ng-floki-i18n'
import { FlokiTypesService } from '@cosmobile/ng-floki-types'
import { TranslateService } from '@ngx-translate/core'
import { filter } from 'rxjs/operators'
import { AttributeSet, FlokiCategoraService } from '@cosmobile/ng-floki-categora'
import { PublishService } from '../../services/publish.service'

interface MenuNode {
  labelKey: string
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'backoffice'
  menuOpened = false
  types: Type[]
  categories: { id: string; name: string; label: string }[]
  menus: { name: string; id: string }[]

  public get currentUser() {
    return this.authService.currentUser
  }

  mobileQuery: MediaQueryList

  // tslint:disable-next-line: variable-name
  private _mobileQueryListener: () => void

  @ViewChild('toolbar', { static: false, read: ElementRef })
  elementView: ElementRef
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav

  contentHeight: number

  public innerWidth: any
  groupedTypes: Array<{ name: string; types: Type[] }>
  categoraTypes: Array<{ name: string; attributeSets: AttributeSet[] }>
  visibleTypes: Type[]
  visualizeTypes: Type[]
  userTypes: Type[]
  estensioniTypes: Type[]
  @HostListener('window:resize', ['$event'])
  checkResize(event?: any) {
    if (this.sidenav) {
      this.innerWidth = window.innerWidth
      if (window.innerWidth <= 1023) {
        this.sidenav.close()
      } else {
        this.sidenav.open()
      }
    }
  }

  constructor(
    private router: Router,
    private authService: FlokiAuthService,
    public titleService: Title,
    public translate: TranslateService,
    private flokiI18nService: FlokiI18nService,
    private typesService: FlokiTypesService,
    private categoraService: FlokiCategoraService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private publishService: PublishService
  ) {
    translate.setDefaultLang('it')
    translate.use(this.flokiI18nService.selectedLanguage.id)
    this.flokiI18nService.SelectedLanguageChange.subscribe((language) => {
      translate.use(language.id)
    })
    titleService.setTitle('Home')

    this.mobileQuery = media.matchMedia('(max-width: 600px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    if (this.mobileQuery.addEventListener) {
      this.mobileQuery.addEventListener('change', this._mobileQueryListener)
    } else {
      // tslint:disable-next-line: deprecation
      this.mobileQuery.addListener(this._mobileQueryListener)
    }
    let inited = false
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async () => {
        if (!inited && (await authService.checkIfLoggedIn())) {
          try {
            await this.init()
            inited = true
          } catch (e) {}
        }
      })
  }

  isLoaded(): boolean {
    return this.publishService.isLoaded
  }

  async init() {
    if (this.currentUser) {
      this.types = await this.typesService.getTypes()
      console.log('types', this.types)
      const TAG_GROUP = 'group:'
      const TAG_ATTRSET = 'categora'
      const TAG_MENU_VISUALIZE = 'menu:visualizzazione'
      const TAG_MENU_UTENTI = 'menu:utenti'
      const TAG_MENU_ESTENSIONI = 'menu:estensioni'
      const groupedTypes = []
      this.visibleTypes = []
      this.visualizeTypes = []
      this.userTypes = []
      this.estensioniTypes = []
      this.categoraTypes = []
      await Promise.all(
        this.types.map(async (type) => {
          if (type.visible) {
            if (type.tags) {
              if (type.tags.some((tag) => tag === TAG_ATTRSET)) {
                const as = await this.categoraService.getAttributeSetsByType(
                  type,
                )
                this.categoraTypes.push({ name: type.name, attributeSets: as })
              }
              if (type.tags.some((tag) => tag.startsWith(TAG_GROUP))) {
                groupedTypes.push(type)
              } else if (type.tags.some((tag) => tag === TAG_MENU_VISUALIZE)) {
                this.visualizeTypes.push(type)
              } else if (type.tags.some((tag) => tag === TAG_MENU_UTENTI)) {
                this.userTypes.push(type)
              } else if (type.tags.some((tag) => tag === TAG_MENU_ESTENSIONI)) {
                this.estensioniTypes.push(type)
              } else {
                this.visibleTypes.push(type)
              }
            } else {
              this.visibleTypes.push(type)
            }
          }
        }),
      )

      this.groupedTypes = groupedTypes.reduce<
        Array<{ name: string; types: Type[] }>
      >((groups, type) => {
        const typeGroup = type.tags
          .find((tag) => tag.startsWith(TAG_GROUP))
          .replace(TAG_GROUP, '')
        let group = groups.find((g) => g.name === typeGroup)
        if (!group) {
          group = {
            name: typeGroup,
            types: [],
          }
          groups.push(group)
        }
        group.types.push(type)
        return groups
      }, [])
      this.groupedTypes.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      this.groupedTypes = this.groupedTypes.map((group) => {
        group.types.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        return group
      })
      console.log('groupedTypes', this.groupedTypes)
      // this.categories = await this.typesService.getMany('Category')
      // this.menus = (await this.typesService.getMany('Menu')).sort((a, b) => {
      //   return a.order - b.order;
      // })
      this.checkResize()
    }

    if (this.elementView) {
      this.contentHeight = this.elementView.nativeElement.offsetHeight
      console.log('contheight ' + this.contentHeight)
    }
  }

  async logout() {
    await this.authService.logout()
  }

  isCategoraType(type: Type) {
    return this.categoraTypes.some((t) => t.name === type.name)
  }

  getTypeAttributesSet(type: Type) {
    return this.categoraTypes.find((t) => t.name === type.name).attributeSets
  }

  ngOnDestroy(): void {
    if (this.mobileQuery.removeEventListener) {
      this.mobileQuery.removeEventListener('change', this._mobileQueryListener)
    } else {
      // tslint:disable-next-line: deprecation
      this.mobileQuery.removeListener(this._mobileQueryListener)
    }
  }
}
