import { FlokiAction, ActionHandler } from '@cosmobile/ng-floki-types'
import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Type } from '@cosmobile/floki-base'
import { SyncService } from '../services/sync.service'
import { DialogService } from '@cosmobile/ng-floki-dialog'

@FlokiAction('launch-sync-static-action')
@Injectable()
export class LaunchSyncStaticAction implements ActionHandler {
  icon = 'map-signs'
  name = 'Sincronizzazione solo pagine statiche'

  constructor(
    protected readonly dialogService: DialogService,
    protected readonly router: Router,
    private syncService: SyncService
  ) {}

  isVisible(type: Type, related: any, currentRoute: ActivatedRoute) {
    if (!related) {
      return true
    }
    return false
  }

  async handle(type: Type, related: any, currentRoute: ActivatedRoute) {
    try {
      const resp: { syncId: string, error?: string } = await this.syncService.launchStaticSync()
      if(resp.error && resp.error === 'progressError') {
        this.dialogService.openHtmlDialog({
          id: 'error-launch-dialog',
          title: 'Errore in avvio sincronizzazione',
          content: `<div><h2>Sincronizzazione ancora in corso</h2></div>`
        })
      } else {
        this.dialogService.openTextDialog({
          id: 'launch-dialog',
          title: `Sincronizzazione ${resp.syncId} lanciata`,
          content: 'Verificare lista e dettaglio per lo stato di avanzamento'
        })
      }
    } catch (error) {
      this.dialogService.openHtmlDialog({
        id: 'error-launch-dialog',
        title: 'Errore in avvio sincronizzazione',
        content: `<div><h2>${error.message}</h2></div>`
      })
    } finally {
      setTimeout(() => {
        this.dialogService.closeDialog()
        this.router.navigate([''], {skipLocationChange: true}).then(
          () => this.router.navigate([`/types/${type.name}`], {skipLocationChange: true})
        )
      }, 3000)
    }
  }
}
