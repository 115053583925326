import { FlokiAction } from '@cosmobile/ng-floki-types'
import { Injectable } from '@angular/core'
import { ActionHandler } from '@cosmobile/ng-floki-types/lib/interfaces/action-handler.interface'
import { Type } from '@cosmobile/floki-base'
import { ActivatedRoute } from '@angular/router'
import { PublishService } from '../services/publish.service'
import { FlokiI18nService } from '@cosmobile/ng-floki-i18n'
import { Language } from '@cosmobile/ng-floki-i18n'
import { SerieService } from '../services/serie.service';



@FlokiAction('serie-duplicate-usa')
@Injectable()
export class SerieDuplicateUsaAction implements ActionHandler {
  icon = {
    name: 'copy',
    color: '#d5aa41',
  }
  name = 'SERIE_DUPLICATE_USA'

  constructor(
    public service: SerieService,
  ) {}

  isVisible(type: Type, related: any, currentRoute: ActivatedRoute) {
    let visible = false
    if (related && related.attributeSet.name && 'Serie' === related.attributeSet.name) {
      visible = true
    }
    return visible
  }

  async handle(type: Type, related: any, currentRoute: ActivatedRoute) {
    await this.service.duplicate(related, true)
    location.reload()
  }
}
