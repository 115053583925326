import { FlokiAction } from '@cosmobile/ng-floki-types'
import { Injectable } from '@angular/core'
import { ActionHandler } from '@cosmobile/ng-floki-types/lib/interfaces/action-handler.interface'
import { Type } from '@cosmobile/floki-base'
import { ActivatedRoute } from '@angular/router'

@FlokiAction('copy-link')
@Injectable()
export class CopyLinkAction implements ActionHandler {
  icon = {
    name: 'link',
    color: 'gainsboro',
  }
  name = 'COPY_LINK'

  constructor() {}

  isVisible(type: Type, related: any, currentRoute: ActivatedRoute) {
    if (related) {
      return true
    }
    return false
  }

  handle(type: Type, related: any, currentRoute: ActivatedRoute) {
    console.log('Action "copy-link" triggered')
  }
}
