import { Injectable } from '@angular/core';
import { DialogService } from '@cosmobile/ng-floki-dialog'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable()
export class SerieService {

  constructor(
    public http: HttpClient,
    private dialogService: DialogService
  ) {}

  private api = `${environment.baseApiUrl}/franchi&kim/serie`

  async duplicate(related, usa?: boolean) {
    usa = usa || false
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    this.dialogService.openTextDialog({
      id: 'duplicate-dialog',
      title: `Duplicazione serie in corso`,
      content: 'Attendi la chiusura di questo avviso per continuare'
    })
    await this.http.post(this.api + '/duplicate/'+related.id+"/"+usa, {headers, }).toPromise()
  }
}
