import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import * as FileSaver from 'file-saver'
import { environment } from '../../environments/environment'
import { DialogService, DialogActionType } from '@cosmobile/ng-floki-dialog'

@Injectable()
export class PublishService {

  private api = `${environment.baseApiUrl}/pubblicazioni`
  private loading = false

  constructor(
    public http: HttpClient,
    private dialogService: DialogService
  ) {}

  get isLoaded(): boolean {
    return !this.loading
  }

  async xmlSchedaTecnicaSerieProdotti(name: string, lang: string, marchio: string, descrizione: string) {
    this.loading = true
    await this.xmlProdotti('st', name, lang, marchio, { descrizione })
  }

  async xmlCatalogoProdotti(name: string, marchio: string, lang: string) {
    this.loading = true
    await this.xmlProdotti('catalogo', name, lang, marchio)
  }

  async xmlListinoProdotti(name: string, marchio: string, lang: string) {
    this.loading = true
    await this.xmlProdotti('listino', name, lang, marchio)
  }

  async buildCatalogoProdotti(name: string, marchio: string, lang: string) {
    this.loading = true
    await this.buildXmlProdotti('catalogo', name, marchio, lang)
  }

  async buildXmlListinoProdotti(name: string, marchio: string, lang: string) {
    this.loading = true
    await this.buildXmlProdotti('listino', name, marchio, lang)
  }

  private async xmlProdotti(type: string, name: string, lang: string, marchio: string, extra?: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    let response
    try {
      if (type !== 'st') {
        if(!marchio) {
          throw new Error('Marchio non valorizzato!')
        }
        const info: any = await this.http.get(`${this.api}/${type}/${name}/${marchio}/${lang}`, { headers }).toPromise()
        let canGo = true

        if (!info) { 
          canGo = false
          this.dialogService.openTextDialog({
            id: 'ErrorDialog',
            title: `Build catalogo/listino in progress`,
            content: `Nessuna build del ${type} ${name} per ${lang} presente.`,
            actions: [
              {
                label: 'Close',
                value: '',
                type: DialogActionType.primary
              }
            ]
          })
        }else if (info.status === 'progress') {
          canGo = false
          this.dialogService.openTextDialog({
            id: 'ErrorDialog',
            title: `Build catalogo/listino in progress`,
            content: `La build del ${type} ${name} per ${lang} è in corso. Riprovare più tardi.`,
            actions: [
              {
                label: 'Close',
                value: '',
                type: DialogActionType.primary
              }
            ]
          })
        }
        if (canGo) {
          response = await this.http.get<Blob>(`${this.api}/${type}/xml/${name}/${marchio}/${lang}`, { headers, responseType: 'blob' as 'json' }).toPromise()
        }
      } else {
        response = await this.http.get<Blob>(`${this.api}/${type}/${name}/${marchio}/${lang}`, { headers, responseType: 'blob' as 'json' }).toPromise()
      }
      let fileLangSuffix = lang
      try {
        fileLangSuffix = lang.substring(0, lang.indexOf('-'))
      } catch (error) {
        console.error(error)
        fileLangSuffix = lang
      }
      if (response) {
        let fileName = `${type}-${name}-${fileLangSuffix}.xml`
        if(type === 'st') {
          let firstPart
          if(extra && extra.descrizione) {
            firstPart = `${name}-${extra.descrizione}-${fileLangSuffix}`
          } else {
            firstPart = `${name}-${fileLangSuffix}`
          }
          firstPart = firstPart ? firstPart.toUpperCase() : firstPart
          fileName = `${firstPart}.xml`
        }
        FileSaver.saveAs(response, fileName)
      }
    } catch (error) {
      console.error(error)
      this.dialogService.openTextDialog({
        id: 'ErrorDialog',
        title: `Errore ${type} per ${name}`,
        content: `${error}`,
        actions: [
          {
            label: 'Close',
            value: '',
            type: DialogActionType.primary
          }
        ]
      })
    } finally {
      this.loading = false
    }
  }

  private async buildXmlProdotti(type: string, name: string, marchio: string, lang: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json')
    try {
      const info: any = await this.http.get(`${this.api}/${type}/${name}/${marchio}/${lang}`, { headers }).toPromise()
      let canGo = true
      if (info && info.status === 'progress') {
        canGo = false
        this.dialogService.openTextDialog({
          id: 'ErrorDialog',
          title: `Build catalogo/listino in progress`,
          content: `La build del ${type} ${name} per ${lang} è in corso. Attendere il completamento e riprovare.`,
          actions: [
            {
              label: 'Close',
              value: '',
              type: DialogActionType.primary
            }
          ]
        })
      }
      if (canGo) {
        await this.http.post(`${this.api}/${type}/${name}/${marchio}/${lang}`, { headers }).toPromise()
      }
    } catch (error) {
      console.error(error)
      this.dialogService.openTextDialog({
        id: 'ErrorDialog',
        title: `Errore ${type} per ${name}`,
        content: `${error}`,
        actions: [
          {
            label: 'Close',
            value: '',
            type: DialogActionType.primary
          }
        ]
      })
    } finally {
      this.loading = false
    }
  }

}
